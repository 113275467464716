import React from 'react';
import { Box, Grid, Card, CardContent, Typography, Avatar } from '@mui/material';

const testimonials = [
  {
    name: 'Ranjana Singh',
    message: 'I’ve been using my new EV two-wheeler for the past six months, and it has completely transformed my daily commute. Not only is it eco-friendly, but it’s also incredibly cost-effective. Charging it costs a fraction of what I used to spend on fuel, and the ride is so smooth and silent. Plus, I never have to worry about traffic with its nimble handling. If you are thinking about going green, this is the way to do it!',
    img: 'https://media.istockphoto.com/id/1331576522/photo/happy-young-girl-sitting-over-motor-scooter.jpg?s=612x612&w=0&k=20&c=eWOZUzEC17_BoJptn6TjOGGQpfHZLruChm4nmSRDAEg=',
  },
  {
    name: 'Ankit Rana',
    message: 'I was initially skeptical about switching to an electric vehicle, but this EV two-wheeler has exceeded my expectations. The pickup is impressive, and the battery life is more than enough for my daily needs. What’s even better is knowing that I’m contributing to reducing air pollution by going electric. It’s comfortable, stylish, and a true powerhouse on the road. Highly recommend it to anyone looking for a sustainable transportation option!',
    img: 'https://img.freepik.com/free-photo/smiling-businessman-face-portrait-wearing-suit_53876-148138.jpg?t=st=1729663458~exp=1729667058~hmac=0167a60a983bd13d4b4c6b0344348e169f68cbee22d899dca3e41d65d067b27b&w=826',
  },
  {
    name: 'Ankita Shrivastav',
    message: 'The EV two-wheeler is everything I hoped for and more. I’ve seen significant savings on fuel, and the maintenance is minimal. Charging is quick and easy, and I love the fact that I am doing my part for the environment. Whether I’m running errands or commuting to work, it’s reliable and hassle-free. It’s a smart investment that has made my life simpler and greener!',
    img: 'https://media.gettyimages.com/id/1666610070/photo/portrait-of-happy-young-couple-on-scooter-enjoying-road-trip.jpg?s=612x612&w=0&k=20&c=-DhclmTCfXuRy5Lm8MqjCQ4jlZ8Agw_ZtInRAX6pvLQ=',
  },
];

const TestimonialCarousel = () => {
  return (
    <Box sx={{ py: 5, px: 3, backgroundColor: '#f4f4f4' }}>
      <Typography variant="h4" align="center" gutterBottom>
        What Our Customers Say
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {testimonials.map((testimonial, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{
              marginBottom: { xs: 2, sm: 0 }, // Adds margin bottom for mobile view (xs)
            }}
          >
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                p: 2,
                boxShadow: 3, // Box shadow
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out', // Smooth transition
                '&:hover': {
                  transform: 'scale(1.05)', // Slight zoom-in effect on hover
                  boxShadow: 6, // Increase box shadow on hover
                },
              }}
            >
              <Avatar
                alt={testimonial.name}
                src={testimonial.img}
                sx={{ width: 80, height: 80, mb: 2 }}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.message}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TestimonialCarousel;
